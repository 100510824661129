<template>
  <div class="session-bg">
    <v-container>
      <v-snackbar
        v-model="snackbarVisible"
        :color="snackbarColor"
        timeout="3000"
        top
      >
        {{ snackbarMessage }}
      </v-snackbar>
      <v-row justify="center">
        <v-col cols="12" sm="8" md="6" lg="4">
          <v-card class="popup-card">
            <v-card-title class="popup-title">Cambiar Contraseña</v-card-title>
            <v-card-text>
              <div v-if="!passwordUpdated">
                <v-form ref="form" v-model="formValid">
                  <v-text-field
                    label="Código de seguridad"
                    v-model="token"
                    :rules="[rules.required]"
                    prepend-icon="mdi-key"
                    clearable
                    outlined
                  />
                  <v-text-field
                    label="Correo electrónico"
                    v-model="email"
                    type="email"
                    :rules="[rules.required, rules.email]"
                    prepend-icon="mdi-email"
                    clearable
                    outlined
                  />
                  <v-text-field
                    label="Contraseña nueva"
                    v-model="newPassword"
                    :type="showNewPassword ? 'text' : 'password'"
                    :append-icon="showNewPassword ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="toggleNewPasswordVisibility"
                    :rules="[rules.required, rules.minLength(8)]"
                    prepend-icon="mdi-lock"
                    clearable
                    outlined
                    error-messages=""
                  />

                  <v-text-field
                    label="Confirmar contraseña nueva"
                    v-model="confirmPassword"
                    :type="showConfirmPassword ? 'text' : 'password'"
                    :append-icon="
                      showConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'
                    "
                    @click:append="toggleConfirmPasswordVisibility"
                    :rules="[
                      rules.required,
                      () =>
                        confirmPassword === newPassword ||
                        'Las contraseñas no coinciden',
                    ]"
                    prepend-icon="mdi-lock-check"
                    clearable
                    outlined
                  />
                  <v-btn
                    color="red"
                    class="white--text"
                    block
                    @click="validateForm"
                  >
                    Guardar
                  </v-btn>
                </v-form>
              </div>

              <div v-else>
                <p>
                  La contraseña se ha cambiado exitosamente. Por favor vuelva a
                  la página principal.
                </p>
                <v-btn color="red" class="white--text" @click="goToLogin">
                  Volver al inicio
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      email: "",
      token: "",
      newPassword: "",
      confirmPassword: "",
      formValid: false,
      showNewPassword: false,
      showConfirmPassword: false,
      snackbarVisible: false,
      snackbarMessage: "",
      snackbarColor: "success",
      passwordUpdated: false,
      rules: {
        required: (v) => !!v || "Este campo es obligatorio",
        email: (v) =>
          /.+@.+\..+/.test(v) || "El correo electrónico no es válido",
        minLength: (min) => (v) =>
          v.length >= min || `Debe tener al menos ${min} caracteres`,
      },
    };
  },
  computed: {
    ...mapState("users", ["responsePassword"]),
  },
  watch: {
    responsePassword() {
      if (this.responsePassword !== null) {
        if (this.responsePassword.error) {
          this.snackbarColor = "red";
          this.snackbarMessage = this.responsePassword.Mensaje;
        } else {
          this.snackbarColor = "green";
          this.snackbarMessage = this.responsePassword.Mensaje;
          this.passwordUpdated = true;
        }

        this.snackbarVisible = true;
        this.$store.dispatch("users/setResponsePassword", null);
      }
    },
  },
  methods: {
    toggleNewPasswordVisibility() {
      this.showNewPassword = !this.showNewPassword;
    },
    toggleConfirmPasswordVisibility() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    validateForm() {
      if (this.$refs.form.validate()) {
        this.savePassword();
      } else {
        this.snackbarColor = "red";
        this.snackbarMessage = "Por favor completa todos los campos.";
        this.snackbarVisible = true;
      }
    },
    async savePassword() {
      await this.$store.dispatch("users/sendPasswordEmail", {
        email: this.email,
        password: this.newPassword,
        token: this.token,
      });
    },
    goToLogin() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style lang="scss">
.session-bg {
  background-color: white;
  height: 100vh;
  align-items: center;
  place-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
}

.popup-card {
  background-color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
}
</style>